export default defineNuxtRouteMiddleware(() => {
  const authStore = useAuth()

  if (authStore.isCurrentLOB('KMB'))
    return navigateTo('/kmb/dashboard')
  if (authStore.isCurrentLOB('KMOB'))
    return navigateTo('/kmob/dashboard')
  if (authStore.isCurrentLOB('WG'))
    return navigateTo('/nwg/dashboard')
  if (authStore.isSuperAdmin)
    return navigateTo('/kmb/dashboard') // temporqary set default to KMB dashboard for Super Admin role
})
